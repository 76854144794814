/**
 * Script for fetching configs synchronously from remote location:
 */

(() => {

  /**
   * Synchronously fetches data from the specified URL using XMLHttpRequest.
   * @param {string} url - The URL from which to fetch the data.
   * @returns {string|undefined} - The response text if the request is successful, otherwise undefined.
  */
  function fetchSync (url) {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, false)

    xhr.send(null)
    if (xhr.status >= 200 && xhr.status < 400) {
      return xhr.responseText
    }

    return undefined
  }

  /**
  * Fetches the version number from a remote configuration file synchronously.
  * If the version number is successfully fetched, it is returned; otherwise, undefined is returned.
  * @returns {string|undefined} - The version number if the request is successful, otherwise undefined.
  */
  function fetchVersion () {
    let response = fetchSync(`${window.ef?.remoteConfig?.versionPath || './assets/version.json'}?t=${encodeURIComponent(Date.now())}`)
    if (response) {
      let json = JSON.parse(response)
      return json.version
    }

    return undefined
  }


  /**
  * Fetches the bundled configuration from a remote location synchronously.
  * If the bundled configuration is successfully fetched, it is returned as a parsed JSON object;
  * otherwise, undefined is returned.
  * @returns {Object|undefined} - The bundled configuration object if the request is successful, otherwise undefined.
  */
  function fetchBundledConfig () {
    let response = fetchSync(`${window.ef?.remoteConfig?.bundledConfigPath || './assets/config/app-config.json'}?=${encodeURIComponent(Date.now())}`)
    if (response) {
      return JSON.parse(response)
    }

    return undefined
  }

  /**
  *Fetches the domain metadata from a specified URL synchronously.
  *If the domain metadata is successfully fetched, it is returned as a parsed JSON object;
  *otherwise, undefined is returned.
  *@returns {Object|undefined} - The domain metadata object if the request is successful, otherwise undefined.
  */
  function fetchDomainMeta () {
    let url = window.location.protocol + '//' + window.location.host
    if (window.cordova) {
      url = window.location.protocol + '//localhost'
    }

    let response = fetchSync(`${url}/config/metadata.json?t=${encodeURIComponent(Date.now())}`)
    if (response) {
      return JSON.parse(response)
    }

    return undefined
  }

  /**
  * Fetches the domain configuration based on the provided version.
  * The configuration includes metadata and configuration data.
  * The 'meta.brand' property is optional in the metadata.
  * 
  * @param {string} version - The version of the configuration to fetch.
  * @returns {Object|undefined} - An object containing 'meta' and 'configuration' properties if the request is successful, otherwise undefined.
  */
  function fetchDomainConfig (version) {
    let meta = fetchDomainMeta()
    if (!meta) {
      return undefined
    }

    let configurationUrl = `${meta.configUrl}/${meta.product}/${meta.type}/${meta.country}`
    configurationUrl += meta.brand ? `/${meta.brand}/` : '/'
    configurationUrl += `${meta.environment}/${version}/env.json?t=${encodeURIComponent(Date.now())}`

    let response = fetchSync(configurationUrl)
    let configuration = JSON.parse(response)
    return { meta, configuration }
  }

  function showMaintenance () {
    document.getElementsByTagName('html')[0].classList.add('hydrated')
    const div = document.getElementById('page-maintenance')
    if (div) {
      div.style.display = 'block'
    }
    document.getElementById('home-loader').style.display = 'none'
  }

  window.ef = window.ef || {}
  window.ef.remoteConfig = window.ef.remoteConfig || {}

  let version = fetchVersion()
  window.ef.remoteConfig.version = version
  let domainConfigResponse = fetchDomainConfig(version)
  if (domainConfigResponse?.configuration) {
    window.ef.remoteConfig.environmentMeta = domainConfigResponse.meta;
    window.ef.remoteConfig.configuration = domainConfigResponse.configuration
  } else {
    let bundledConfigResponse = fetchBundledConfig()
    if (bundledConfigResponse) {
      window.ef.remoteConfig.configuration = bundledConfigResponse
    } else {
      window.ef?.remoteConfig?.showMaintenance ? window.ef.remoteConfig.showMaintenance() : showMaintenance()
    }
  }
})()
